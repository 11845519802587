import { useState, useCallback } from 'react'

import { Divider, Loader } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import { navigate, useParams } from '@redwoodjs/router'
import { routes } from '@redwoodjs/router'
import { Metadata, useMutation, useQuery } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import QuestionRow from 'src/components/Inputs/QuestionRow/QuestionRow'
import {
  GET_VOE_BY_ID,
  SUBMIT_EXTERNAL_COMPANY_RESPONSE,
} from 'src/graphql/voe.graphql'
import IconAlertCircle from 'src/icons/IconAlertCircle'
import Header from 'src/pages/DriverApplicationPage/components/Header'

import AccidentsCard from '../ApplicantDetailsPage/tabs/ApplicationTab/components/AccidentsCard'
import ApplicationCompletedScreen from '../DriverApplicationPage/screens/ApplicationCompletedScreen'
import CompanyInfoCard from '../VoERequestDetailsPage/components/CompanyInfoCard'
import DriverInfoCard from '../VoERequestDetailsPage/components/DriverInfoCard'
import RequestContentColumn from '../VoERequestDetailsPage/components/RequestContentColumn'
import ResponseContentColumn from '../VoERequestDetailsPage/components/ResponseContentColumn'

const VoEResposePage = () => {
  const params = useParams()

  const schema = z.object({
    positionHeld: z
      .string()
      .min(1, { message: 'This field is required' })
      .max(200, { message: '200 character max' }),
    startDate: z.date(),
    endDate: z.date().optional().nullable(),
    reasonForLeaving: z
      .string()
      .min(1, { message: 'This field is required' })
      .max(1000, { message: '1000 character max' }),
    isDriverTerminated: z.boolean().optional().nullable(),
    isDriverSubjectToFMCSRs: z.boolean().optional().nullable(),
    isJobDesignatedAsSafetySensitiveFunctionUnderDOT: z
      .boolean()
      .optional()
      .nullable(),
    eligibleForRehire: z.boolean(),
    accidents: z.array(
      z.object({
        accidentDate: z.date().optional(),
        city: z.string().min(1).max(100),
        state: z.string().min(2).max(50),
        description: z.string().min(1).max(1000),
        isPreventable: z.boolean(),
        isCommercialMotorVehicleInvolved: z.boolean(),
      })
    ),
  })
  const [showFeedback, setShowFeedback] = useState(false)

  const form = useForm({
    validate: zodResolver(schema),
  })

  const [submitVoEResponse, { loading: loadingSubmit }] = useMutation(
    SUBMIT_EXTERNAL_COMPANY_RESPONSE,
    {
      onCompleted: () => {
        setShowFeedback(true)
      },
      refetchQueries: [
        {
          query: GET_VOE_BY_ID,
          variables: {
            id: params.voeId,
          },
        },
      ],
    }
  )

  const { data: voe, loading } = useQuery(GET_VOE_BY_ID, {
    variables: {
      id: params.voeId,
    },
    onCompleted: () => {
      handleInitialValues()
    },
    onError: () => {
      navigate(routes.forbidden())
    },
  })

  const handleSubmit = useCallback((values) => {
    submitVoEResponse({
      variables: {
        id: params.voeId,
        input: {
          ...values,
        },
      },
    })
  }, [])

  const handleInitialValues = () => {
    form.setValues(
      voe?.verificationOfEmployment.status === 'PENDING_REVIEW' ||
        voe?.verificationOfEmployment.status === 'COMPLETED'
        ? {
            positionHeld:
              voe.verificationOfEmployment.responseContent.positionHeld,
            startDate: new Date(
              voe.verificationOfEmployment.responseContent.startDate
            ),
            endDate: voe.verificationOfEmployment.responseContent.endDate
              ? new Date(voe.verificationOfEmployment.responseContent.endDate)
              : null,
            reasonForLeaving:
              voe.verificationOfEmployment.responseContent.reasonForLeaving,
            eligibleForRehire:
              voe.verificationOfEmployment.responseContent.eligibleForRehire,
            isDriverTerminated:
              voe.verificationOfEmployment.responseContent.isDriverTerminated,
            isDriverSubjectToFMCSRs:
              voe.verificationOfEmployment.responseContent
                .isDriverSubjectToFMCSRs,
            isJobDesignatedAsSafetySensitiveFunctionUnderDOT:
              voe.verificationOfEmployment.responseContent
                .isJobDesignatedAsSafetySensitiveFunctionUnderDOT,
            accidents: voe.verificationOfEmployment.accidents,
          }
        : {
            accidents: voe.verificationOfEmployment.accidents,
            positionHeld:
              voe.verificationOfEmployment.requestContent.positionHeld,
            startDate: new Date(
              voe.verificationOfEmployment.requestContent.startDate
            ),
            endDate: voe.verificationOfEmployment.requestContent.endDate
              ? new Date(voe.verificationOfEmployment.requestContent.endDate)
              : null,
            reasonForLeaving:
              voe.verificationOfEmployment.requestContent.reasonForLeaving,
            eligibleForRehire: null,
            isDriverTerminated:
              voe.verificationOfEmployment.requestContent.isDriverTerminated,

            isDriverSubjectToFMCSRs:
              voe.verificationOfEmployment.requestContent
                .isDriverSubjectToFMCSRs,
            isJobDesignatedAsSafetySensitiveFunctionUnderDOT:
              voe.verificationOfEmployment.requestContent
                .isJobDesignatedAsSafetySensitiveFunctionUnderDOT,
          }
    )
  }

  const handleAccidentsUpdate = useCallback(async ({ accidents }) => {
    form.setFieldValue(`accidents`, accidents)
    return { errors: null }
  }, [])

  const isCompleted = voe?.verificationOfEmployment.status === 'PENDING_REVIEW'

  if (loading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <Loader />
      </div>
    )
  }
  if (!voe) {
    return
  }

  return (
    <>
      {!showFeedback && (
        <form
          className="flex h-full flex-col gap-6  overflow-auto px-6 pb-10"
          onSubmit={form.onSubmit((values) => handleSubmit(values))}
        >
          <Header companyId={voe.verificationOfEmployment.companyId} />
          <Metadata
            title="VoE Response"
            description="VoE Response page"
            robots="noindex,nofollow"
          />
          <CompanyInfoCard voe={voe.verificationOfEmployment} />
          <DriverInfoCard
            voe={voe.verificationOfEmployment}
            noApplicantLink={true}
          />

          <div className="flex flex-col gap-6 rounded-lg border border-doubleNickel-gray-200">
            <div className="flex flex-col gap-6 p-6">
              <div className="text-md font-semibold text-doubleNickel-gray-900">
                Validate information
              </div>
              <div className="text-sm text-doubleNickel-gray-500">
                Use the buttons next to the fields or reject the information. In
                case of invalid information, please insert the correct
                information.
              </div>
              <div className="flex flex-row gap-8 ">
                <RequestContentColumn
                  form={form}
                  request={voe.verificationOfEmployment.requestContent}
                />
                <Divider orientation="vertical" />
                <ResponseContentColumn
                  editing={!isCompleted}
                  form={form}
                  request={voe.verificationOfEmployment.requestContent}
                />
              </div>
              <div className="flex flex-row items-center gap-4 rounded-lg bg-doubleNickel-brand-25 px-4 py-3">
                <IconAlertCircle className="fill-none stroke-doubleNickel-brand-500" />
                <div className="flex-1">
                  <QuestionRow
                    required
                    text="Is this applicant eligible for rehire?"
                    value={form.values.eligibleForRehire as boolean}
                    handleChange={(value) =>
                      form.setFieldValue(`eligibleForRehire`, value === 'true')
                    }
                    editing={!isCompleted}
                  />
                </div>
              </div>
            </div>
          </div>
          {form.values.accidents && (
            <AccidentsCard
              values={form.values.accidents}
              handleUpdateApplication={handleAccidentsUpdate}
              readOnly={isCompleted}
            />
          )}
          {!isCompleted && (
            <div className="flex w-full flex-row justify-end">
              {!loadingSubmit && (
                <Button
                  disabled={!form.isValid()}
                  text="Submit"
                  type="submit"
                />
              )}
              {loadingSubmit && <Loader size="sm" />}
            </div>
          )}
        </form>
      )}
      {showFeedback && (
        <div className="flex min-h-screen items-center justify-center">
          <ApplicationCompletedScreen
            title="Information Submitted!"
            subTitle={'Contact support with any questions'}
            showNavigationButton={false}
          />
        </div>
      )}
    </>
  )
}

export default VoEResposePage
